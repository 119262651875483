import "./index.css"


function Section7 (){

  return (
    <section className="section-7">

      <div className="section-7-container">
          <h1>Meet the team</h1>
          <div className="card-container">
            <div className="card">
              <div className="left">
                <img src={require('../Section1/images/niftyOne.png')}></img>
                <p>Founder</p>
              </div>
              <div className="right">
                <h3>Yoonarva</h3>
                <p>likes a bunch of stuff. i'm just writing o fil in space
                  and maybe he can just write his own bio to make things
                  easier. #Chi for life becaue she is such an amazing partner.
                  I live in a very nice apartment. I love my job.....
                  # nifty for life.
                  #Jadu is my son.</p>
              </div>
            </div>
            <div className="card">
              <div className="left">
                  <img src={require('../Section1/images/niftyOne.png')}></img>
                  <p>Founder</p>
                </div>
                <div className="right">
                  <h3>LilCroc</h3>
                  <p> fs;fjsdklfj asdfsd asdf sdfsdf sf sdfs dfsdf sdf sf sadfsd f. asdfdsfsdf asdfsdfsdfsdfs sdfsdfsdfsd sf sdf sdfsd. sdfsadf sadf sdf sdfsdfsdf s s sdfds f asdf sdf sdfsd. dsfsadf sdfsdf sdfs dfsdf sfdsdfsd asdfsd fsdf sd</p>
                </div>
            </div>
            <div className="card">
              <div className="left">
                  <img src={require('../Section1/images/niftyOne.png')}></img>
                  <p>Artist</p>
                </div>
                <div className="right">
                  <h3>ycatsmeow</h3>
                  <p>fs;fjsdklfj asdfsd asdf sdfsdf sf sdfs dfsdf sdf sf sadfsd f. asdfdsfsdf asdfsdfsdfsdfs sdfsdfsdfsd sf sdf sdfsd. sdfsadf sadf sdf sdfsdfsdf s s sdfds f asdf sdf sdfsd. dsfsadf sdfsdf sdfs dfsdf sfdsdfsd asdfsd fsdf sd</p>
                </div>
            </div>
            <div className="card">
              <div className="left">
                  <img src={require('../Section1/images/niftyOne.png')}></img>
                  <p>Developer</p>
                </div>
                <div className="right">
                  <h3>ImCodePanda</h3>
                  <p>fs;fjsdklfj asdfsd asdf sdfsdf sf sdfs dfsdf sdf sf sadfsd f. asdfdsfsdf asdfsdfsdfsdfs sdfsdfsdfsd sf sdf sdfsd. sdfsadf sadf sdf sdfsdfsdf s s sdfds f asdf sdf sdfsd. dsfsadf sdfsdf sdfs dfsdf sfdsdfsd asdfsd fsdf sd</p>
                </div>
            </div>

          </div>


      </div>
    </section>
  )
}

export default Section7
