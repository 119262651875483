import questions from "./questionData";
import Questions from "./Questions";

import "./index.css"

function Section8 (){



  return (
    <section className="section-8">
      <div className='faqs-box'>
        <div className='faqs-info'>

            <div className='faqs-header'>
                <h1>Curious?</h1>
            </div>

          <div className='faqs-questions-box'>
            {questions.map((question, index) => (
              <div className='faqs-questions-container' key={index}>
                <Questions question={question} />
              </div>
            ))}
          </div>

        </div>
      </div>
    </section>
  )
}

export default Section8
