
import "./index.css"

function Section6 (){

  return (
    <section className="section-6">
      <div className="section-6-container">
        <div className="roadmap-container">
            <div className="element-container-1">
            <div className="circle-element one"></div>
            </div>

            <div className="element-container-2">
              <div className="circle-element two"></div>
            </div>
          </div>
      </div>
    </section>
  )
}

export default Section6
