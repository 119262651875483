const questions = [
    {"q": "What's the total supply?",
        "answer": "n/a"
        },
     {"q": "Why choose this NFT?",
          "answer": "n/a"
        },
    {"q": "Perks of owning nifty Creatures",
          "answer": "n/a"
        },
     {"q": "When is it launching?",
          "answer": "n/a"
        },
     {"q": "How to buy the NFT",
          "answer": "n/a"
        },
     {"q": "Is there a launch roadmap?",
          "answer": "n/a"
        }

    ]

export default questions;
