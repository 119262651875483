
import "./index.css"

function Section75 () {

  return (
    <div className="section-75"></div>
  )
}

export default Section75
