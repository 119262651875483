
import "./Menu.css"

function Menu () {

  return (
    <div className="menu">
      <button className="menubtn"><img src={require("../images/pinkstar.png")}></img></button>
      <div className="menu-content">
        <a href="#">who are we?</a>
        <a href="#">rares/nfts</a>
        <a href="#">roadmap</a>
        <a href="#">team</a>
        <a href="#">FAQ</a>

      </div>
    </div>
  )
}

export default Menu
