
import "./index.css"

function Section3 (){

//   const rareContainer = document.getElementById('rareContainer');
// const rareScrollWidth = rareContainer.scrollWidth;

// window.addEventListener('load', () => {
//   window.self.setInterval(() => {
//     if (rareContainer.scrollLeft !== rareScrollWidth) {
//       rareContainer.scrollTo(rareContainer.scrollLeft + 1, 0);
//     }
//   }, 15);
// });
  return (
    <section className="section-3">
      <div className="section-3-container">
        <h1>Rare Creatures</h1>

        <div className="rare-container" id="rareContainer">
          <div className="rare-items">
            <img src={require('../Section1/images/niftyOne.png')}></img>
          </div>
          <div className="rare-items"></div>
          <div className="rare-items"></div>
          <div className="rare-items"></div>
          <div className="rare-items"></div>
          <div className="rare-items"></div>
          <div className="rare-items"></div>
          <div className="rare-items"></div>
          {/* <div className="rare-items"></div>
          <div className="rare-items"></div> */}
        </div>
      </div>
    </section>
  )
}

export default Section3
