
import "./index.css"

function Section4 (){

  return (
    <section className="section-4">
      <div className="section-4-container">
        <h1>Road map</h1>
        <div className="roadmap-container">
          <div className="element-container-1">
           <div className="circle-element one"></div>
          </div>

          <div className="element-container-2">
            <div className="circle-element two"></div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section4
