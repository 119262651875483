import logo from './logo.svg';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// import ComingSoon from './components/ComingSoon'
import Spa from './components/Spa';

function App() {
  return (
    <>

    <BrowserRouter>

        <Routes>

            <Route path="/" exact={true} element={<Spa/>} >

            </Route>
        </Routes>

    </BrowserRouter>
    </>


  );
}

export default App;
