import {useState} from 'react';

import "./index.css";

function  Questions({question}) {
    const [open, setOpen] = useState(false);


    const showInfo = () => {
        !open ? setOpen(true) : setOpen(false)
    }

    return (
        <>

            <div onClick={showInfo} className='questions'>

                    <h1>{question.q}</h1>
                
                {open ?
                <div>MORE INFO</div>
                : ""
                }
            </div>


        </>
    )
}

export default Questions;
