
import "./index.css"
import Menu from "./Menu"
import moment from "moment";
import Particles from "react-tsparticles";

function Section1 (){


  let date = moment().format('MMMM Do YYYY')

  const particlesInit = (main) => {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  };

  const particlesLoaded = (container) => {
    console.log(container);
  };

  return (
    <section className="section-1">
      <div className="section-1-container">

        <div className="nav">
          <div>
            <button className="nifty-logo">
              <a href={"https://www.niftycreatures.io"}>
                <img></img>
                <span >Nifty Creatures Logo</span>
              </a>
            </button>
          </div>
          <div className="nav-links">
            <button>
              <a href={"https://www.niftycreatures.io"}>
                <img className="discordImg" src={require("./images/discord.png")}></img>
              </a>
            </button>
            <button>
              <a href={"https://www.instagram.com/niftycreatures/?hl=en"}>
                <img className="instaImg" src={require("./images/insta.png")}></img>
              </a>
            </button>
            <button>
              <a href={"https://twitter.com/NiftyCreatures"}>
                <img className="twitterImg" src={require("./images/twitter.png")}></img>
              </a>
            </button>
            {/* <button>
              <a href={"https://www.niftycreatures.io"}>
                <img></img>
                <span>wal</span>
              </a>
            </button> */}
            <button>
              <a href={"https://opensea.io/collection/nifty-creatures"}>
                <img className="openSeaImg" src={require("./images/opensea.png")}></img>
              </a>
            </button>
            <Menu />

          </div>
        </div>
        <div className="msg1-container">
          <div className="msg1">
            <h1>Today is {date}, we've landed on a <span className='green'>green</span> and <span className='blue'>blue</span> planet...</h1>
          </div>
          <div className="niftFloat-container">
            <div className="nif one"><img src={require("./images/niftyOne.png")}></img></div>
            <div className="nif two"><img src={require("./images/niftyOne.png")}></img></div>
            <div className="nif three"><img src={require("./images/niftyOne.png")}></img></div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Section1
