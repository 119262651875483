

import Section1 from "../Section1"
import Section2 from "../Section2"
import Section3 from "../Section3"
import Section4 from "../Section4"
import Section5 from "../Section5"
import Section6 from "../Section6"
import Section7 from "../Section7"
import Section75 from "../Section75"
import Section8 from "../Section8"

import ReactParticle from "../ReactParticle"

function Spa(){
  return (
    <div className="spa-container">
      <ReactParticle></ReactParticle>
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section75 />
      <Section8 />

    </div>
  )
}


export default Spa
