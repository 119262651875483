
import "./index.css"

function Section2 (){

  return (
    <section className="section-2">
      <div className="section-2-container">
        <div><h1>WHERE DID WE COME FROM?</h1></div>
        <div className="whymsg">
          <p>Nifty Creatures are born from a very unique star that exploded. First of all, we ARE NOT ALIENS!!
 We are CREATURES. We've floated for years in the galaxy in search of a home. You can say were kinda like galaxy nomads. We don't
 know where we are but we are excited to explore! Watch out for us!
          </p>
          <img></img>
        </div>

      </div>
    </section>
  )
}

export default Section2

