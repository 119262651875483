import "./index.css"


function Section5 (){

  return (
    <section className="section-5">
      <div className="section-5-container">
        <div className="roadmap-container">
            <div className="element-container-1">
            <div className="circle-element one"></div>
            </div>

            <div className="element-container-2">
              <div className="circle-element two"></div>
            </div>
          </div>
      </div>
    </section>
  )
}

export default Section5
